import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="ブランディングとは - ブランド" mdxType="SEO" />
    <PageTitle title="ブランディングとは" enTitle="About Branding" mdxType="PageTitle" />
    <h2 {...{
      "id": "はじめに",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%AF%E3%81%98%E3%82%81%E3%81%AB",
        "aria-label": "はじめに permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`はじめに`}</h2>
    <h3 {...{
      "id": "ブランディングとは",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%96%E3%83%A9%E3%83%B3%E3%83%87%E3%82%A3%E3%83%B3%E3%82%B0%E3%81%A8%E3%81%AF",
        "aria-label": "ブランディングとは permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ブランディングとは？`}</h3>
    <p>{`ブランディングとは企業や商品、サービスなどの"らしさ"を時代やニーズを捉えながら、ビジュアルや体験を通して社内外に一貫して届けていくことです。その目的は、ブランドに共感してもらい、ファンになってもらうことです。ブランディングによって創られたブランド価値は、企業やサービスの資産にもなります。`}</p>
    <figure>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0ad112b85b5eef33a37dfdff6efe6731/21b4d/about-branding.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "60%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0ad112b85b5eef33a37dfdff6efe6731/2aaaf/about-branding.webp 160w", "/static/0ad112b85b5eef33a37dfdff6efe6731/85e47/about-branding.webp 320w", "/static/0ad112b85b5eef33a37dfdff6efe6731/75198/about-branding.webp 640w", "/static/0ad112b85b5eef33a37dfdff6efe6731/691bc/about-branding.webp 960w", "/static/0ad112b85b5eef33a37dfdff6efe6731/223e5/about-branding.webp 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0ad112b85b5eef33a37dfdff6efe6731/69538/about-branding.png 160w", "/static/0ad112b85b5eef33a37dfdff6efe6731/72799/about-branding.png 320w", "/static/0ad112b85b5eef33a37dfdff6efe6731/6af66/about-branding.png 640w", "/static/0ad112b85b5eef33a37dfdff6efe6731/d9199/about-branding.png 960w", "/static/0ad112b85b5eef33a37dfdff6efe6731/21b4d/about-branding.png 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/0ad112b85b5eef33a37dfdff6efe6731/6af66/about-branding.png",
                "alt": "Brandingという言葉を「brand」と「ing」に分解して説明する図",
                "title": "Brandingという言葉を「brand」と「ing」に分解して説明する図",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <figcaption className="visually-hidden">図は、左側：「brand」と右側：「ing」に分けて構成されている。「brand」は「らしさ」、つまり「なぜ、なんのために存在しているのか？〇〇らしさ」を表現し、「意志・歴史・理念・強み・特徴・独自性」をもつ。一方、「ing」は「届け方」、つまり「どんな方法で伝え、届け、心の中に醸成させるか？」を表現し、「ロゴ・カラー・音・言葉・空間・活動・社員・体験」をもつ。この図の参考文献：「Landor 事例で学ぶブランディング」</figcaption>
    </figure>
    <h3 {...{
      "id": "ブランディングの役割",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%96%E3%83%A9%E3%83%B3%E3%83%87%E3%82%A3%E3%83%B3%E3%82%B0%E3%81%AE%E5%BD%B9%E5%89%B2",
        "aria-label": "ブランディングの役割 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ブランディングの役割`}</h3>
    <p>{`ブランドのあるべき姿を定義することによって組織やサービスが目指す方向性に一貫性が生まれ、「ブランドのらしさ」が明確に伝わります。`}</p>
    <figure>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b90ce851d586ccd482e69b109b678311/21b4d/role-of-branding.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "102.50000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/b90ce851d586ccd482e69b109b678311/2aaaf/role-of-branding.webp 160w", "/static/b90ce851d586ccd482e69b109b678311/85e47/role-of-branding.webp 320w", "/static/b90ce851d586ccd482e69b109b678311/75198/role-of-branding.webp 640w", "/static/b90ce851d586ccd482e69b109b678311/691bc/role-of-branding.webp 960w", "/static/b90ce851d586ccd482e69b109b678311/223e5/role-of-branding.webp 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/b90ce851d586ccd482e69b109b678311/69538/role-of-branding.png 160w", "/static/b90ce851d586ccd482e69b109b678311/72799/role-of-branding.png 320w", "/static/b90ce851d586ccd482e69b109b678311/6af66/role-of-branding.png 640w", "/static/b90ce851d586ccd482e69b109b678311/d9199/role-of-branding.png 960w", "/static/b90ce851d586ccd482e69b109b678311/21b4d/role-of-branding.png 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/b90ce851d586ccd482e69b109b678311/6af66/role-of-branding.png",
                "alt": "ブランディングの役割を説明する図",
                "title": "ブランディングの役割を説明する図",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <figcaption className="visually-hidden">「Brand」を中心に、6つの要素「値段、SNS、広告、広報とPR、社員、プロダクト」それぞれに向かって矢印が伸びており、6つは円状に配置されている。</figcaption>
    </figure>
    <h3 {...{
      "id": "ミッションビジョンバリューの相関関係",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%9F%E3%83%83%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%93%E3%82%B8%E3%83%A7%E3%83%B3%E3%83%90%E3%83%AA%E3%83%A5%E3%83%BC%E3%81%AE%E7%9B%B8%E9%96%A2%E9%96%A2%E4%BF%82",
        "aria-label": "ミッションビジョンバリューの相関関係 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ミッション、ビジョン、バリューの相関関係`}</h3>
    <p>{`ミッション、ビジョン、バリューを定義することによって目指す指針が明確になります。`}</p>
    <figure>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/21b4d/relationship.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "57.50000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/2aaaf/relationship.webp 160w", "/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/85e47/relationship.webp 320w", "/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/75198/relationship.webp 640w", "/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/691bc/relationship.webp 960w", "/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/223e5/relationship.webp 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/69538/relationship.png 160w", "/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/72799/relationship.png 320w", "/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/6af66/relationship.png 640w", "/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/d9199/relationship.png 960w", "/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/21b4d/relationship.png 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/36c6a8f82f0fd8a7654bcd3ba8c353d2/6af66/relationship.png",
                "alt": "ミッション、ビジョン、バリューの相関関係を説明するピラミッド図",
                "title": "ミッション、ビジョン、バリューの相関関係を説明するピラミッド図",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <figcaption className="visually-hidden">ピラミッド図は3層からなり、最下層である3層目がValue、2層目がVision、最上層はMissionと書かれている。3層目のValueは、「共通の価値観 - How」を表し、「どのように（How）目指すのか、会社が大切にしている価値観や行動指針」を示す。2層目は「理想とする姿 - What」を表し、「何を（What）を目指すのか、会社が目指すベき理想の姿」を示す。1層目は「我々の存在意義 - Why」を表し、「なぜ（Why）そのビジネスを行なっているのか、企業の存在意義、不変、恒久のもの、輝き続けるもの、北極星」を示す。</figcaption>
    </figure>
    <h2 {...{
      "id": "ブランドアイデンティティ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%82%A2%E3%82%A4%E3%83%87%E3%83%B3%E3%83%86%E3%82%A3%E3%83%86%E3%82%A3",
        "aria-label": "ブランドアイデンティティ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ブランドアイデンティティ`}</h2>
    <p>{`ブランディングにおいて核となるのがブランドアイデンティティ、つまり「らしさとは何か」という定義です。それはコピーやロゴに限らず、サービスの提供価値やブランドパーソナリティ、ベネフィット、ボイス&トーンなどによって形成されており、その“らしさ”を体現するための実行原理として「Brand Concept」があります。ブランドを創るためには、この“らしさ”をすべてのタッチポイントにおいて、一貫して届ける必要があります。`}</p>
    <figure>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d6bdba4f7bbaac803970df3baf8d2754/21b4d/user-touchpoints-bi.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "105%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d6bdba4f7bbaac803970df3baf8d2754/2aaaf/user-touchpoints-bi.webp 160w", "/static/d6bdba4f7bbaac803970df3baf8d2754/85e47/user-touchpoints-bi.webp 320w", "/static/d6bdba4f7bbaac803970df3baf8d2754/75198/user-touchpoints-bi.webp 640w", "/static/d6bdba4f7bbaac803970df3baf8d2754/691bc/user-touchpoints-bi.webp 960w", "/static/d6bdba4f7bbaac803970df3baf8d2754/223e5/user-touchpoints-bi.webp 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d6bdba4f7bbaac803970df3baf8d2754/69538/user-touchpoints-bi.png 160w", "/static/d6bdba4f7bbaac803970df3baf8d2754/72799/user-touchpoints-bi.png 320w", "/static/d6bdba4f7bbaac803970df3baf8d2754/6af66/user-touchpoints-bi.png 640w", "/static/d6bdba4f7bbaac803970df3baf8d2754/d9199/user-touchpoints-bi.png 960w", "/static/d6bdba4f7bbaac803970df3baf8d2754/21b4d/user-touchpoints-bi.png 1280w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/d6bdba4f7bbaac803970df3baf8d2754/6af66/user-touchpoints-bi.png",
                "alt": "「Brand Identity」を説明する図",
                "title": "「Brand Identity」を説明する図",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <figcaption className="visually-hidden">図は3重の円からなり、中心が「Brand Identity」、2周目が「Brand Elements」、3周目の一番外周が「User Touchpoints」とある。Brand Identityには「らしさ」があり、Brand Elementsにはロゴ、カラー、ボイス、イラストレーション、モチーフ、カタチ、タイプフェイスがあり、User Touchpointsにはウェブサイト、アプリ、SNS、オフィス、採用、広告、CS、営業、映像、本、値段がある。</figcaption>
    </figure>
    <h2 {...{
      "id": "ビジュアルアイデンティティ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%93%E3%82%B8%E3%83%A5%E3%82%A2%E3%83%AB%E3%82%A2%E3%82%A4%E3%83%87%E3%83%B3%E3%83%86%E3%82%A3%E3%83%86%E3%82%A3",
        "aria-label": "ビジュアルアイデンティティ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ビジュアルアイデンティティ`}</h2>
    <p>{`ビジュアルアイデンティティ（VI）とは、ブランドアイデンティティを一貫したビジュアルの世界観で展開していくことです。
VIによってブランドの強みやらしさ、世界観を社内外に正しくすばやく認知させ、効率的・効果的に浸透させることができます。それらをデザインするための約束事が「デザイン原則」になります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6831c417bf424305c47fe5deddeca717/21b4d/visual-identity.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6831c417bf424305c47fe5deddeca717/2aaaf/visual-identity.webp 160w", "/static/6831c417bf424305c47fe5deddeca717/85e47/visual-identity.webp 320w", "/static/6831c417bf424305c47fe5deddeca717/75198/visual-identity.webp 640w", "/static/6831c417bf424305c47fe5deddeca717/691bc/visual-identity.webp 960w", "/static/6831c417bf424305c47fe5deddeca717/223e5/visual-identity.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6831c417bf424305c47fe5deddeca717/69538/visual-identity.png 160w", "/static/6831c417bf424305c47fe5deddeca717/72799/visual-identity.png 320w", "/static/6831c417bf424305c47fe5deddeca717/6af66/visual-identity.png 640w", "/static/6831c417bf424305c47fe5deddeca717/d9199/visual-identity.png 960w", "/static/6831c417bf424305c47fe5deddeca717/21b4d/visual-identity.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6831c417bf424305c47fe5deddeca717/6af66/visual-identity.png",
              "alt": "Visual Identityを表すイメージ画像",
              "title": "Visual Identityを表すイメージ画像",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <figure>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/db609067bd0bd27536d6bf2120b5660c/9cea8/user-touchpoins-vi.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "116.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/db609067bd0bd27536d6bf2120b5660c/2aaaf/user-touchpoins-vi.webp 160w", "/static/db609067bd0bd27536d6bf2120b5660c/85e47/user-touchpoins-vi.webp 320w", "/static/db609067bd0bd27536d6bf2120b5660c/75198/user-touchpoins-vi.webp 640w", "/static/db609067bd0bd27536d6bf2120b5660c/691bc/user-touchpoins-vi.webp 960w", "/static/db609067bd0bd27536d6bf2120b5660c/05ca0/user-touchpoins-vi.webp 1278w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/db609067bd0bd27536d6bf2120b5660c/69538/user-touchpoins-vi.png 160w", "/static/db609067bd0bd27536d6bf2120b5660c/72799/user-touchpoins-vi.png 320w", "/static/db609067bd0bd27536d6bf2120b5660c/6af66/user-touchpoins-vi.png 640w", "/static/db609067bd0bd27536d6bf2120b5660c/d9199/user-touchpoins-vi.png 960w", "/static/db609067bd0bd27536d6bf2120b5660c/9cea8/user-touchpoins-vi.png 1278w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/db609067bd0bd27536d6bf2120b5660c/6af66/user-touchpoins-vi.png",
                "alt": "「Visual Identity」を説明する図",
                "title": "「Visual Identity」を説明する図",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <figcaption className="visually-hidden">図は3重の円からなり、中心が「Visual Identity」、2周目が「Design Principles」、3週目の一番外周が「User Touchpoints」とある。User Touchpointsにはアプリ、サムネイル、SNS、オフィス、書籍、スタッフブログ、CM、広告とPR、映像、グッズ、ウェブサイトがある。</figcaption>
    </figure>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      